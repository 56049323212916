<template>
  <div>
    <van-popup v-model:show="isSku" position="bottom" round safe-area-inset-bottom :close-on-click-overlay="false" lock-scroll>
      <div class="sku">
        <div class="flex flex-left sku-one" :class="!isSpec?'sku-one1': ''">
          <div class="sku-one-img" :style="{background: 'url('+ picUrl +')no-repeat center center',backgroundSize: 'cover'}"></div>
          <div class="sku-one-info">
            ¥{{parseFloat(specPrice/100).toFixed(2)}}
          </div>
        </div>
        <!-- <div class="sku-two" v-if="isSpec">
          <div class="sku-two-title">规格名称</div>
          <div class="flex flex-left sku-two-list">
            <div class="sku-two-list-l" v-for="item in 3" :key="item.id">
              <div class="sku-two-list-l-img"></div>
              <div class="sku-two-list-l-name">规格规格</div>
            </div>
          </div>
        </div> -->
        <div class="sku-two" v-if="isSpec">
          <div class="sku-two-title" v-if="goodsSpec1.length !=0">{{goodsSpec1.spec1Name}}</div>
          <div class="flex flex-left flex-wrap sku-two-list">
            <div class="flex flex-center align-center sku-two-list-guige" v-for="(item1, index) in goodsSpec1.specdata" :key="item1.id" @click="selectGuigeFunc(0,index)" :class="selectedSpec1==index?'sku-two-list-guige1':''">
              {{item1}}
            </div>
          </div>
          <div class="sku-two-title" v-if="goodsSpec2.length !=0">{{goodsSpec2.spec1Name}}</div>
          <div class="flex flex-left flex-wrap sku-two-list">
            <div class="flex flex-center align-center sku-two-list-guige" v-for="(item1, index1) in goodsSpec2.specdata" :key="item1.id"  @click="selectGuigeFunc(1,index1)" :class="selectedSpec2==index1?'sku-two-list-guige1':''">
              {{item1}}
            </div>
          </div>
        </div>
        <div class="flex flex-between align-center sku-three">
          <div class="sku-three-l">
            购买数量<span>库存&nbsp;{{goodsDetail.stockNumber}}&nbsp;件</span>
          </div>
          <van-stepper v-model="buyNum" theme="round" button-size="22" disable-input />
        </div>
        <div class="sku-four">
          <van-button class="sku-four-btn" round size="large" color="#E62129" @click="lijiBuyFunc" v-if="platfrom === 'goodsdetail'">立即购买</van-button>
          <van-button class="sku-four-btn" round size="large" color="#E62129" @click="closePopupFunc" v-if="platfrom === 'ordertrue'">确认商品</van-button>
        </div>

        <div class="sku-close" @click="closePopupFunc"></div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { inject } from 'vue';
// import { getUrlParam } from '@/utils/common.js';
export default {
  setup() {
    let isSku = inject('issku');
    let platfrom = inject('platfrom');
    return {
      isSku,
      platfrom
    }
  },
  data() {
    return {
      buyNum: 1,
      goodsDetail: '',
      specArr: [],
      isSpec: false,
      price: 0,
      originPrice: 0,
      picUrl: '',
      spec1Name: '',
      spec2Name: '',
      kuCunNum: 0,
      specPrice: 0,
      specId: '',
      spec1: [],
      spec2: [],
      goodsSpec1: [],
      goodsSpec2: [],
      specData: [],
      selectedSpec1: 0, // 选中的规格1 下标
      selectedSpec2: 0, // 选中的规格2 下标
      shareMemberId: '',
      activityId: ''
    }
  },
  created() {
    this.goodsDetail = JSON.parse(sessionStorage.getItem('goodsdetail'));
    if (this.platfrom === 'ordertrue') {
      this.buyNum = sessionStorage.getItem('buyNum')
    }
  },
  methods: {
    // 处理商品规格
    specArrFunc() {
      let oData = this.goodsDetail;
      if (this.goodsDetail.spec1Name || this.goodsDetail.spec2Name) {
        this.isSpec = true;
        let spec1Name = oData.spec1Name;
        let spec2Name = oData.spec2Name;
        if ((spec1Name != "" && spec1Name != null && spec1Name != undefined) || (spec2Name != "" && spec2Name != null && spec2Name != undefined)) {
            if (oData.mallEntityGoodSpecList.length > 0) {
                let oGuige = oData.mallEntityGoodSpecList;
                let arr = [],
                    arr1 = [],
                    arrJson1 = [],
                    arrJson2 = [];
                for (let i = 0; i < oGuige.length; i++) {
                    arr.push(oGuige[i].spec1Attr);
                    arr1.push(oGuige[i].spec2Attr);
                }
                arr = this.arrayFunc(arr, arr);
                arr1 = this.arrayFunc(arr1, arr1);
                this.specPrice = oGuige[0].price;
                this.kuCunNum = oGuige[0].storeNum;
                this.specId = oGuige[0].id;
                if (oData.spec1Name != null && oData.spec1Name != '' && oData.spec1Name != undefined) {
                    let aJson = {
                        'spec1Name': oData.spec1Name,
                        'specdata': arr
                    }
                    arrJson1 = aJson;
                    this.spec1 = arr[0];
                }
                if (oData.spec2Name != null && oData.spec2Name != '' && oData.spec2Name != undefined) {
                    let aJson = {
                        'spec1Name': oData.spec2Name,
                        'specdata': arr1
                    }
                    arrJson2 = aJson;
                    this.spec2 = arr1[0];
                }
                this.goodsSpec1 = arrJson1;
                this.goodsSpec2 = arrJson2;
                this.specData = oGuige;
            } else {
                this.specPrice = oData.salePrice;
                this.kuCunNum = oData.stockNumber;
            }
        } else {
            this.specPrice = oData.salePrice;
            this.kuCunNum = oData.stockNumber;
        }

        console.log('this.specPrice', this.specPrice)

        if (oData.remarkPics != '' && oData.remarkPics != null) {
            this.remarksPic = oData.remarkPics.split('|');
        }

        setTimeout(() => {
            this.isPageShow = true;
        }, 1000);
      } else {
        this.specPrice = oData.salePrice;
        this.kuCunNum = oData.stockNumber;
      }

      this.originPrice = this.goodsDetail.originalPrice;

      this.picUrl = this.goodsDetail.picture.split(',')[0];
    },
    // 数组去重
    arrayFunc(a, b) {
        let arr = a.concat(b);
        let result = [];
        let obj = {};
        for (let i of arr) {
            if (!obj[i]) {
                result.push(i);
                obj[i] = 1;
            }
        }
        return result;
    },
    // 选择规格
    selectGuigeFunc(index, index1) {
        console.log('index', index)
        let aSpec1 = '',
            aSpec2 = '';
        let oSpec1 = this.goodsSpec1,
            oSpec2 = this.goodsSpec2;

        if (index == 0) {
            this.selectedSpec1 = index1;
            aSpec1 = oSpec1.specdata[index1];
            this.spec1 = aSpec1;
        } else {
            this.selectedSpec2 = index1;
            aSpec2 = oSpec2.specdata[index1];
            this.spec2 = aSpec2;
        }

        for (let i = 0; i < this.specData.length; i++) {
            if (this.specData[i].spec1Attr == this.spec1 && this.specData[i].spec2Attr == this.spec2) {
                this.specPrice = this.specData[i].price;
                this.kuCunNum = this.specData[i].storeNum;
                this.specId = this.specData[i].id;
            }
        }
    },
    // 关闭弹窗
    closePopupFunc() {
      this.isSku = false;
      // 选择的规格
      let aSpec1Name = '';
      let aSpec2Name = '';
      if (this.goodsDetail.spec1Name == '' || this.goodsDetail.spec1Name == null || this.goodsDetail.spec1Name == undefined) {
          aSpec1Name = '';
      } else {
          aSpec1Name = this.goodsDetail.spec1Name + ': ' + this.spec1;
      }

      if (this.goodsDetail.spec2Name == '' || this.goodsDetail.spec2Name == null || this.goodsDetail.spec2Name == undefined) {
          aSpec2Name = '';
      } else {
          aSpec2Name = this.goodsDetail.spec2Name + ': ' + this.spec2;
      }
      let sSpecName = aSpec1Name+';'+aSpec2Name;
      this.$emit('buynumfunc', this.buyNum, sSpecName)
    },
    // 立即购买
    lijiBuyFunc() {
      console.log('this.activityId', this.activityId)
      if (this.goodsDetail.limitNumber != null) {
        if (this.buyNum > this.kuCunNum || this.buyNum > this.goodsDetail.limitNumber) {
            if (this.buyNum >= this.goodsDetail.limitNumber) {
                this.$toast('已达限购上限！');
                return;
            }

            if (this.buyNum >= this.kuCunNum) {
                this.$toast('库存不足！');
                return;
            }
        } else {
          let imgUrl = '';
          let aSpec1Name = '';
          let aSpec2Name = '';
          if (this.goodsDetail.picture.indexOf(',') != -1) {
              imgUrl = this.goodsDetail.picture.split(',')[0];
          } else {
              imgUrl = this.goodsDetail.picture;
          }

          if (this.goodsDetail.spec1Name == '' || this.goodsDetail.spec1Name == null || this.goodsDetail.spec1Name == undefined) {
              aSpec1Name = '';
          } else {
              aSpec1Name = this.goodsDetail.spec1Name + ': ' + this.spec1;
          }

          if (this.goodsDetail.spec2Name == '' || this.goodsDetail.spec2Name == null || this.goodsDetail.spec2Name == undefined) {
              aSpec2Name = '';
          } else {
              aSpec2Name = this.goodsDetail.spec2Name + ': ' + this.spec2;
          }
          this.$router.push({
            path: '/orderTrue',
            query: {
              goodsId: this.goodsDetail.id,
              buyNum: this.buyNum,
              spec1Name: aSpec1Name,
              spec2Name: aSpec2Name,
              goodsUrl: imgUrl,
              price: this.specPrice,
              originPrice: this.originPrice,
              specId: this.specId,
              activityId: this.activityId ? this.activityId : ''
            }
          })
        }
      } else {
        let imgUrl = '',
            aSpec1Name = '',
            aSpec2Name = '';
        if (this.goodsDetail.picture.indexOf(',') != -1) {
            imgUrl = this.goodsDetail.picture.split(',')[0];
        } else {
            imgUrl = this.goodsDetail.picture;
        }

        if (this.goodsDetail.spec1Name == '' || this.goodsDetail.spec1Name == null || this.goodsDetail.spec1Name == undefined) {
            aSpec1Name = '';
        } else {
            aSpec1Name = this.goodsDetail.spec1Name + ': ' + this.spec1;
        }

        if (this.goodsDetail.spec2Name == '' || this.goodsDetail.spec2Name == null || this.goodsDetail.spec2Name == undefined) {
            aSpec2Name = '';
        } else {
            aSpec2Name = this.goodsDetail.spec2Name + ': ' + this.spec2;
        }

        this.$router.push({
          path: '/orderTrue',
          query: {
            goodsId: this.goodsDetail.id,
            buyNum: this.buyNum,
            spec1Name: aSpec1Name,
            spec2Name: aSpec2Name,
            goodsUrl: imgUrl,
            price: this.specPrice,
            originPrice: this.originPrice,
            specId: this.specId,
            shareMemberId: sessionStorage.getItem('shareMemberId'),
            activityId: this.activityId ? this.activityId : ''
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.sku{
  position: relative;
  width: auto;
  height: auto;
  padding: 15px;
  &-one{
    width: 100%;
    height: auto;
    padding: 2px 0px 17px;
    border-bottom: 1px solid #EEEEEE;
    &-img{
      width: 65px;
      height: 65px;
      margin-right: 18px;
      background-color: red;
    }
    &-info{
      width: auto;
      height: auto;
      flex: 1;
      overflow: hidden;
      color: #E62129;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
    }
  }
  &-one1{
    border-bottom: 0px solid #EEEEEE;
  }
  &-two{
    width: auto;
    height: auto;
    padding: 15px 0;
    &-title{
      width: 100%;
      height: auto;
      color: #333333;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    &-list{
      width: 100%;
      height: auto;
      &-l{
        width: 105px;
        height: auto;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #FDF4E5;
        border: 1px solid #EEEEEE;
        overflow: hidden;
        margin-right: 13px;
        &-img{
          width: 100%;
          height: 105px;
          overflow: hidden;
        }
        &-name{
          width: auto;
          height: auto;
          color: #333333;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          padding: 8px 0px;
        }
      }
      &-l1{
        border: 1px solid #FDF4E5;
        &-name1{
          color: #F39801;
        }
      }
      &-l:last-child{
        margin-right: 0px;
      }

      &-guige{
        width: auto;
        height: 35px;
        color: #333333;
        font-size: 13px;
        font-weight: 400;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 0 20px;
        border-radius: 35px;
        border: 1px solid #EEEEEE;
      }
      &-guige1{
        color: #F39801;
        background-color: #FDF4E5;
        border: 1px solid #FDF4E5;
      }
    }
  }
  &-three{
    width: auto;
    height: auto;
    padding: 15px 0 25px;
    border-top: 1px solid #EEEEEE;
    &-l{
      width: auto;
      height: auto;
      color: #333333;
      font-size: 15px;
      font-weight: 400;
      span{
        color: #999999;
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }
  &-four{
    width: 100%;
    height: 40px;
    &-btn{
      height: 100%;
    }
  }
  &-close{
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    height: 20px;
    background: url("../assets/img/close1.png")no-repeat center center;
    background-size: cover;
  }
}
</style>